import React from 'react'
import { motion } from "framer-motion";
import { div } from 'framer-motion/client';


function GetReady() {
  return (
    <div className='my-16'>
        <div className="flex flex-col items-center justify-center md:p-20 p-6 md:mx-20 mx-10 rounded-[16px] bg-cover bg-center" style={{ backgroundImage: `url(${require('../images/section.png')})` }}>
            <h1 className="text-3xl text-center mb-4 text-white font-[700] text-[30px] md:text-[40px]">Ready to supercharge your skills?</h1>
            <p className="text-lg text-center mb-6 max-w-md text-[12px] md:text-[15px] text-white">
                Our open, positive and proactive approach helps us find ways to align your work.
            </p>
            <div className="flex space-x-4">
                <motion.button 
                className="bg-orange-600 text-white px-4 py-2 md:px-6 md:py-2 rounded hover:bg-primary-dark transition duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                >
                GET STARTED
                </motion.button>
                {/* Uncomment for a second button */}
                {/* <motion.button 
                className="bg-gray-300 text-black px-4 py-2 md:px-6 md:py-2 rounded hover:bg-gray-400 transition duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                >
                Contact Us
                </motion.button> */}
            </div>
        </div>
    </div>

  )
}

export default GetReady