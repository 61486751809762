import React from 'react'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-cover bg-center mt-28" style={{ backgroundImage: `url(${require('../images/footer.png')})` }}>
      <div className='flex-row md:flex lg:flex justify-between pt-28 pb-24 md:px-16 px-6 text-primary-dark'>
        <div className='text-primary-dark'>
          <img src={require('../images/logo.png')} alt=""  className='h-10 mb-5'/>
          <p>
            © 2023 TechEra Africa <br />
            All Rights Reserved.
          </p>
          <div className='flex gap-1 my-5'>
            <img src={require('../images/Calling.png')} alt="" />
            Tel: +234 809 440 2222
          </div>
          <div className='flex gap-1 my-5'>
            <img src={require('../images/Message.png')} alt="" />
            Email: contact@techeraafrica.com
          </div>
          <div className='flex gap-6'>
            <img src={require('../images/facebook.png')} alt="" />
            <img src={require('../images/instagram.png')} alt="" />
            <img src={require('../images/mingcute_linkedin-fill.png')} alt="" />
            <img src={require('../images/youtube.png')} alt="" />
            <img src={require('../images/mage_x.png')} alt="" />
          </div>

        </div>
        <div className='flex-row md:flex mt-10 md:0 lg:flex gap-7'>
          <div>
            <h3 className='text-black font-[700]'>Programs</h3>
            <p className='my-3'><Link to='/interest/devops/'>Devops</Link></p>
            <p className='my-3'><Link to='/interest/cloud-computing'>Cloud Computing</Link></p>
            <p className='my-3'><Link to='/interest/virtual-reality'>Virtual Reality Dev</Link></p>
            <p className='my-3'><Link to='/interest/full-stack'>Full Stack</Link></p>
            <p className='my-3'><Link to='/interest/cyber-security'>Cyber Security</Link></p>
            <p className='my-3'><Link to='/interest/data-analysis'>Data Analysis</Link></p>
          </div>
          <div>
            <h3 className='text-black font-[700]'>Company</h3>
            <p className='my-3'><a href="">About</a></p>
            <p className='my-3'><a href="">Blog</a></p>
            <p className='my-3'><a href="">Careers</a></p>
          </div>
          <div></div>
        </div>
        <div className=''>
          <p className='text-black md-1 md:mb-5 lg:mb-5 text-[15px]'>Stay up to date with the latest news</p>
          <div className='bg-white p-2 rounded-[15px] w-fit'>
            <input type="text" placeholder='Email' className='outline-none' />
            <button className='bg-primary-dark md:py-[13px] py-[7.5px] px-[40px] md:px-[50px] rounded-[20px] text-white'>Send</button>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer