import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import devopsimg from '../images/devops-img.png'
import cloudcomputingimg from '../images/cloud-computing.png'
import vrimg from '../images/vr.png'
import fullstackimg from '../images/fullstack.png'
import cyberimg from '../images/cyber-security.png'
import dataanalysisimg from '../images/data-analysis.png'
const programs = [
    { 
        id: 1, 
        name: 'Devops', 
        description: 'Learn the principles and practices of DevOps to improve your workflow.', 
        imgSrc: devopsimg, 
        nameLink: (
            <Link to='/interest/devops/'>
                Devops
            </Link>
        )},
    { 
        id: 2, 
        name: 'Cloud Computing', 
        description: 'Master cloud services and infrastructure.', 
        imgSrc: cloudcomputingimg,
        nameLink: (
            <Link to='/interest/cloud-computing'>
                Cloud Computing
            </Link>
        )},
    { id: 3, name: 'VR Development', description: 'Build immersive VR applications.', imgSrc: vrimg, 
        nameLink: (
            <Link to='/interest/virtual-reality'>
                VR Development
            </Link>
        )
     },
    { id: 4, name: 'Full Stack', description: 'Become proficient in both frontend and backend technologies.', imgSrc: fullstackimg, 
        nameLink: (
            <Link to='/interest/full-stack'>
                Full Stack
            </Link>
        )
     },
    { id: 5, name: 'Cyber Security', description: 'Learn techniques to secure networks and applications.', imgSrc: cyberimg,
        nameLink: (
            <Link to='/interest/cyber-security'>
                Cyber Security
            </Link>
        )
     },
    { id: 6, name: 'Data Analysis', description: 'Gain insights from data through analytical skills.', imgSrc: dataanalysisimg, 
        nameLink: (
            <Link to='/interest/data-analysis'>
                Data Analysis
            </Link>
        )
     },
];

function Program() {
    const [selectedProgram, setSelectedProgram] = useState(programs[0].id);

  return (
    <div className='px-6 md:px-16 mt-20 mb-10'>
        <div className='bg-[#F5F5F5] text-primary-dark rounded-[10px] w-fit py-[10px] px-[25px] mb-5 md:mb-0'>
            <p>Programs</p>
        </div>

        <div className='md:w-[55%]'>
            <h2 className='font-[700] text-[20px] md:text-[50px] lg:text-[40px]'>Your Roadmap to Success</h2>
            <p>Whether you're looking to advance your career, learn new technologies, or develop critical soft skills, our courses will guide you every step of the way on your journey to success.</p>
        </div>

        <div className="flex flex-col md:flex-row mt-10 gap-2">
            {programs.map(program => (
                <div
                    key={program.id}
                    onClick={() => setSelectedProgram(program.id)}
                    // className={`cursor-pointer p-4 transition-all rounded-md duration-300 ease-in-out ${
                    //     selectedProgram === program.id ? 'w-3/4 bg-teal-600 text-white' : 'w-1/6 bg-teal-200'
                    // }`}
                    className={`cursor-pointer p-4 transition-all rounded-md duration-300 ease-in-out ${
                        selectedProgram === program.id ? 'w-full md:w-3/4 bg-teal-600 text-white' : 'w-full md:w-1/6 bg-teal-200'
                    }`}
                >
                    <div className="flex flex-col h-[50%] justify-between">
                        <div>
                            <p className="font-bold text-xl mb-2">{program.id < 10 ? `0${program.id}` : program.id}</p>
                            {/* <p className="text-lg">{program.name}</p> */}
                            {selectedProgram !== program.id && (
                                // <p className="text-lg transform rotate-90 origin-left whitespace-nowrap">
                                <p className="text-lg md:-rotate-90 whitespace-nowrap mt-0 md:mt-10">
                                    {program.name}
                                </p>
                            )}
                        </div>
                        {selectedProgram === program.id && (
                            <div className='flex gap-2'>
                                <div>
                                    <p className="text-lg">{program.nameLink}</p>
                                    <p className="mt-4">{program.description}</p>
                                </div>
                                <img src={program.imgSrc} alt={program.name} className=" w-60 h-6w-60 object-cover" />
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Program