// import React from 'react'

// function Feedback() {
//   return (
//     <div>
//         <div>Testimonials</div>
//         <div>Our Student Feedback</div>

//         <div>
//             <p>Don't just take our word for it - see what actual users of our service have to say about their experience.</p>
//             <div>
//                 <button>Previous</button>
//                 <button>Next</button>
//             </div>

//             <div className='flex'>
//                 <div className='w-[30%] border-2 border-orange-200'>
//                     <div className='text-orange-400 text-left'>stars</div>
//                     <h2>Titilayo Adesanoye</h2>
//                     <small>Software Developer</small>
//                     <p>
//                         Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. 
//                         Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. 
//                     </p>
//                 </div>
//                 <div>
//                     <div className='text-orange-400 text-left'>stars</div>
//                     <h2>Titilayo Adesanoye</h2>
//                     <small>Software Developer</small>
//                     <p>
//                         Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. 
//                         Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. 
//                     </p>
//                 </div>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default Feedback

// import React from 'react';
// import Slider from 'react-slick';
// import { FaStar } from 'react-icons/fa';

// function Feedback() {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3, // Default: Show 3 slides on large screens
//     slidesToScroll: 1,
//     nextArrow: <button>Next</button>,
//     prevArrow: <button>Previous</button>,
//     responsive: [
//       {
//         breakpoint: 1024, // For screens less than 1024px
//         settings: {
//           slidesToShow: 2, // Show 2 slides on medium screens
//         },
//       },
//       {
//         breakpoint: 768, // For screens less than 768px
//         settings: {
//           slidesToShow: 1, // Show 1 slide on small screens
//         },
//       },
//     ],
//   };
  

//   const testimonials = [
//     {
//       name: "Titilayo Adesanoye",
//       title: "Software Developer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. "
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     }
//   ];

//   return (
//     <div className='max-w-full px-16'>
//       {/* <div >Testimonials</div> */}
//       <div className='bg-[#F5F5F5] text-primary-dark rounded-[10px] w-fit py-[10px] px-[25px] mb-5 md:mb-0'>
//           <p>Testimonials</p>
//       </div>
//       <div>
//         <p className='font-[700] text-[30px] md:text-[50px] lg:text-[40px]'>Our Student Feedback</p>
//       </div>
//       <p className="text-left mb-4 font-[400] md:w-[35%] w-[90%] text-[15px] md:text-[18px]">Don't just take our word for it - see what actual users of our service have to say about their experience.</p>

//       <div className="flex flex-col items-center max-w-full">
//         <Slider
//           {...settings}
//           className="max-w-full flex justify-center"
//         >
//           {testimonials.map((testimonial, index) => (
//             <div
//               key={index}
//               className=" justify-center items-center w-full sm:w-1/2 lg:w-1/3 p-4"
//             >
//               <div className="w-[100%] h-[300px] border-2 border-orange-200 p-4 flex flex-col justify-between">
//                 <div className='flex justify-between'>
//                   <div>
//                     <img src={require('../images/review-lady.jpg')} alt="Logo" className="h-[60px] w-[60px] mr-3" />
//                   </div>
//                   <div className="text-orange-400 flex">
//                     {[...Array(5)].map((_, i) => (
//                       <FaStar key={i} />
//                     ))}
//                   </div>
//                 </div>
//                 <h2 className="-mt-6 font-[700] text-[22px]">{testimonial.name}</h2>
//                 <small className="-mt-10 text-[12px] font-[400]">{testimonial.title}</small>
//                 <p className="-mt-10">{testimonial.feedback}</p>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>

//     </div>
//   );
// }

// export default Feedback;


// import React from 'react';
// import Slider from 'react-slick';
// import { FaStar } from 'react-icons/fa';

// function Feedback() {
//   // Custom Next Arrow
//   const NextArrow = ({ onClick }) => (
//     <button
//       className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-orange-500 text-white p-2 rounded-full hover:bg-orange-600"
//       onClick={onClick}
//     >
//       Next
//     </button>
//   );

//   // Custom Previous Arrow
//   const PrevArrow = ({ onClick }) => (
//     <button
//       className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-orange-500 text-white p-2 rounded-full hover:bg-orange-600"
//       onClick={onClick}
//     >
//       Previous
//     </button>
//   );

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3, // Default: Show 3 slides on large screens
//     slidesToScroll: 1,
//     nextArrow: <NextArrow />,
//     prevArrow: <PrevArrow />,
//     responsive: [
//       {
//         breakpoint: 1024, // For screens less than 1024px
//         settings: {
//           slidesToShow: 2, // Show 2 slides on medium screens
//         },
//       },
//       {
//         breakpoint: 768, // For screens less than 768px
//         settings: {
//           slidesToShow: 1, // Show 1 slide on small screens
//         },
//       },
//     ],
//   };

//   const testimonials = [
//     {
//       name: "Titilayo Adesanoye",
//       title: "Software Developer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. "
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     }
//   ];

//   return (
//     <div className='max-w-full px-16'>
//       <div className='bg-[#F5F5F5] text-primary-dark rounded-[10px] w-fit py-[10px] px-[25px] mb-5 md:mb-0'>
//         <p>Testimonials</p>
//       </div>
//       <div>
//         <p className='font-[700] text-[30px] md:text-[50px] lg:text-[40px]'>Our Student Feedback</p>
//       </div>
//       <p className="text-left mb-4 font-[400] md:w-[35%] w-[90%] text-[15px] md:text-[18px]">Don't just take our word for it - see what actual users of our service have to say about their experience.</p>

//       <div className="flex flex-col items-center max-w-full relative">
//         <Slider
//           {...settings}
//           className="max-w-full flex justify-center"
//         >
//           {testimonials.map((testimonial, index) => (
//             <div
//               key={index}
//               className="justify-center items-center w-full sm:w-1/2 lg:w-1/3 p-4"
//             >
//               <div className="w-[100%] h-[300px] border-2 border-orange-200 p-4 flex flex-col justify-between">
//                 <div className='flex justify-between'>
//                   <div>
//                     <img src={require('../images/review-lady.jpg')} alt="Logo" className="h-[60px] w-[60px] mr-3" />
//                   </div>
//                   <div className="text-orange-400 flex">
//                     {[...Array(5)].map((_, i) => (
//                       <FaStar key={i} />
//                     ))}
//                   </div>
//                 </div>
//                 <h2 className="-mt-6 font-[700] text-[22px]">{testimonial.name}</h2>
//                 <small className="-mt-10 text-[12px] font-[400]">{testimonial.title}</small>
//                 <p className="-mt-10">{testimonial.feedback}</p>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// }

// export default Feedback;


// import React, { useRef } from 'react';
// import Slider from 'react-slick';
// import { FaStar } from 'react-icons/fa';

// function Feedback() {
//   const sliderRef = useRef(null);

//   const settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 3, // Default: Show 3 slides on large screens
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024, // For screens less than 1024px
//         settings: {
//           slidesToShow: 2, // Show 2 slides on medium screens
//         },
//       },
//       {
//         breakpoint: 768, // For screens less than 768px
//         settings: {
//           slidesToShow: 1, // Show 1 slide on small screens
//         },
//       },
//     ],
//   };

//   const testimonials = [
//     {
//       name: "Titilayo Adesanoye",
//       title: "Software Developer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. "
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     }
//   ];

//   return (
//     <div className='max-w-full px-16'>
//       <div className='bg-[#F5F5F5] text-primary-dark rounded-[10px] w-fit py-[10px] px-[25px] mb-5 md:mb-0'>
//         <p>Testimonials</p>
//       </div>
//       <div>
//         <p className='font-[700] text-[30px] md:text-[50px] lg:text-[40px]'>Our Student Feedback</p>
//       </div>
      
//       <div className='flex justify-between items-center'>
//         <p className="text-left mb-4 font-[400] md:w-[35%] w-[90%] text-[15px] md:text-[18px]">Don't just take our word for it - see what actual users of our service have to say about their experience.</p>
//         {/* Button Container */}
//         <div className="flex mt-4 space-x-4">
//             <button
//               // className="o text-white px-4 py-2 rounded hover:bg-orange-600"
//               className="px-4 py-2 border-2 border-black text-black rounded hover:bg-primary-dark hover:text-white transition duration-300"
//               onClick={() => sliderRef.current.slickPrev()}
//             >
//               Previous
//             </button>
//             <button
//               // className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
//               className="px-4 py-2 border-2 border-black text-black rounded hover:bg-primary-dark hover:text-white transition duration-300"
//               onClick={() => sliderRef.current.slickNext()}
//             >
//               Next
//             </button>
//         </div>
//       </div>
//       <div className="flex flex-col items-center max-w-full">
//         <Slider ref={sliderRef} {...settings} className="max-w-full flex justify-center">
//           {testimonials.map((testimonial, index) => (
//             <div key={index} className="justify-center items-center w-full sm:w-1/2 lg:w-1/3 p-4">
//               <div className="w-[100%] h-[300px] border-2 border-orange-200 p-4 flex flex-col justify-between">
//                 <div className='flex justify-between'>
//                   <div>
//                     <img src={require('../images/review-lady.jpg')} alt="Logo" className="h-[60px] w-[60px] mr-3" />
//                   </div>
//                   <div className="text-orange-400 flex">
//                     {[...Array(5)].map((_, i) => (
//                       <FaStar key={i} />
//                     ))}
//                   </div>
//                 </div>
//                 <h2 className="-mt-6 font-[700] text-[22px]">{testimonial.name}</h2>
//                 <small className="-mt-10 text-[12px] font-[400]">{testimonial.title}</small>
//                 <p className="-mt-10">{testimonial.feedback}</p>
//               </div>
//             </div>
//           ))}
//         </Slider>
        
       
//       </div>
//     </div>
//   );
// }

// export default Feedback;


// import React, { useRef, useState } from 'react';
// import Slider from 'react-slick';
// import { FaStar } from 'react-icons/fa';

// function Feedback() {
//   const sliderRef = useRef(null);
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: { slidesToShow: 2 },
//       },
//       {
//         breakpoint: 768,
//         settings: { slidesToShow: 1 },
//       },
//     ],
//   };

//   const testimonials = [
//     {
//       name: "Titilayo Adesanoye",
//       title: "Software Developer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. "
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     },
//     {
//       name: "Jane Doe",
//       title: "Product Designer",
//       feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
//     }
//   ];

//   return (
//     <div className='max-w-full px-16'>
//       <div className='bg-[#F5F5F5] text-primary-dark rounded-[10px] w-fit py-[10px] px-[25px] mb-5 md:mb-0'>
//         <p>Testimonials</p>
//       </div>
//       <div>
//         <p className='font-[700] text-[30px] md:text-[50px] lg:text-[40px]'>Our Student Feedback</p>
//       </div>
      
//       <div className='flex-row md:flex justify-between items-center'>
//         <p className="text-left mb-4 font-[400] md:w-[35%] w-[90%] text-[15px] md:text-[18px]">
//           Don't just take our word for it - see what actual users of our service have to say about their experience.
//         </p>
        
//         <div className="flex mt-4 space-x-4">
//           <button
//             className="px-4 py-2 border-2 border-black text-black rounded hover:bg-primary-dark hover:text-white transition duration-300"
//             onClick={() => sliderRef.current.slickPrev()}
//             disabled={currentSlide === 0}
//           >
//             Previous
//           </button>
//           <button
//             className="px-4 py-2 border-2 border-black text-black rounded hover:bg-primary-dark hover:text-white transition duration-300"
//             onClick={() => sliderRef.current.slickNext()}
//             disabled={currentSlide === testimonials.length - settings.slidesToShow}
//           >
//             Next
//           </button>
//         </div>
//       </div>

//       <div className="flex flex-col items-center max-w-full">
//         <Slider ref={sliderRef} {...settings} className="max-w-full flex justify-center">
//           {testimonials.map((testimonial, index) => (
//             <div key={index} className="justify-center items-center w-full sm:w-1/2 lg:w-1/3 p-4">
//               <div className="w-[100%] h-[300px] border-2 border-orange-200 p-4 flex flex-col justify-between">
//                 <div className='flex justify-between'>
//                   <div>
//                     <img src={require('../images/review-lady.jpg')} alt="Logo" className="h-[60px] w-[60px] mr-3" />
//                   </div>
//                   <div className="text-orange-400 flex">
//                     {[...Array(5)].map((_, i) => (
//                       <FaStar key={i} />
//                     ))}
//                   </div>
//                 </div>
//                 <h2 className="mt-2 md:-mt-6 font-[700] text-[15px] md:text-[22px]">{testimonial.name}</h2>
//                 <small className="mt-2 md-mt-10 text-[15px] md:text-[12px] font-[400]">{testimonial.title}</small>
//                 <p className="mt-2 md:-mt-10">{testimonial.feedback}</p>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// }

// export default Feedback;


import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { FaStar } from 'react-icons/fa';

function Feedback() {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  // const testimonials = [
  //   {
  //     name: "Titilayo Adesanoye",
  //     title: "Software Developer",
  //     feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. "
  //   },
  //   {
  //     name: "Jane Doe",
  //     title: "Product Designer",
  //     feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
  //   },
  //   {
  //     name: "Jane Doe",
  //     title: "Product Designer",
  //     feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
  //   },
  //   {
  //     name: "Jane Doe",
  //     title: "Product Designer",
  //     feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
  //   }
  // ];

  const testimonials = [
    {
      name: "Titilayo Adesanoye",
      title: "Software Developer",
      feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
      image: require('../images/review-lady.jpg') // Replace with the actual path to the image
    },
    {
      name: "Jane Doe",
      title: "Product Designer",
      feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      image: require('../images/titi.jpg') // Replace with the actual path to the image
    },
    {
      name: "Jane Doe",
      title: "Product Designer",
      feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      image: require('../images/ertytre.jpg') // Replace with the actual path to the image
    },
    {
      name: "Jane Doe",
      title: "Product Designer",
      feedback: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      image: require('../images/review-lady.jpg') // Replace with the actual path to the image
    }
  ];
  
  return (
    <div className='max-w-full md:px-16 px-6'>
      <div className='bg-[#F5F5F5] text-primary-dark rounded-[10px] w-fit py-[10px] px-[25px] mb-5 md:mb-0'>
        <p>Testimonials</p>
      </div>
      <div>
        <p className='font-[700] text-[30px] md:text-[50px] lg:text-[40px]'>Our Student Feedback</p>
      </div>
      
      <div className='flex-row md:flex justify-between items-center'>
        <p className="text-left mb-4 font-[400] md:w-[35%] w-[90%] text-[15px] md:text-[18px]">
          Don't just take our word for it - see what actual users of our service have to say about their experience.
        </p>
        
        <div className="flex mt-4 space-x-4">
          <button
            className="px-4 py-2 border-2 border-black text-black rounded hover:bg-primary-dark hover:text-white transition duration-300"
            onClick={() => sliderRef.current.slickPrev()}
            disabled={currentSlide === 0}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 border-2 border-black text-black rounded hover:bg-primary-dark hover:text-white transition duration-300"
            onClick={() => sliderRef.current.slickNext()}
            disabled={currentSlide === testimonials.length - settings.responsive[1].settings.slidesToShow}
          >
            Next
          </button>
        </div>
      </div>

      <div className="flex flex-col items-center max-w-full">
        <Slider ref={sliderRef} {...settings} className="max-w-full flex justify-center">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="justify-center items-center w-full sm:w-1/2 lg:w-1/3 p-4">
              <div className="w-[100%] h-[300px] border-2 border-orange-200 p-4 flex flex-col justify-between">
                <div className='flex justify-between'>
                  <div>
                    {/* <img src={require('../images/review-lady.jpg')} alt="Logo" className="h-[60px] w-[60px] mr-3" /> */}
                    <img src={testimonial.image} alt={testimonial.name} className="h-[60px] w-[60px] mr-3" />
                  </div>
                  <div className="text-orange-400 flex">
                    {[...Array(5)].map((_, i) => (
                      <FaStar key={i} />
                    ))}
                  </div>
                </div>
                <h2 className="mt-2 font-[700] text-[15px] md:text-[22px]">{testimonial.name}</h2>
                <small className="mt-2 text-[15px] md:text-[12px] font-[400]">{testimonial.title}</small>
                <p className="mt-2">{testimonial.feedback}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Feedback;

