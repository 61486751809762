import React from 'react'
import { motion } from "framer-motion";

function Hero() {
  // const { scrollYProgress } = useScroll();
  // const scaleX = useSpring(scrollYProgress, {
  //   stiffness: 100,
  //   damping: 30,
  //   restDelta: 0.001
  // });
  return (
    <div className='bg-primary-main md:mb-32 lg:mb-32'>
      <div className=" flex flex-col-reverse md:flex-row items-center justify-between flex-wrap container mx-auto px-6 py-16 md:py-32 w-full mt-20 md:mt-0">
    
        {/* Text Div */}
        <div className="w-full md:w-[60%] mb-8 md:mb-0 mt-11 md:mt-0 text-primary-dark">
          {/* <h1 className="text-3xl md:text-4xl lg:text-5xl font-montserrat font-bold mb-4 ">
            Discovering,<br /> Developing and <br /> <span className=''>Deploying Tech Dreams</span>
          </h1> */}
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-montserrat font-bold mb-4">
            <span className="block mb-2">Discovering,</span>
            <span className="block mb-2">Developing and</span>
            <span className="block">Deploying Tech Dreams</span>
          </h1>

          <p className="mb-6 text-gray-500 font-sans text-sm md:text-base lg:text-[18px]">
            Welcome to Techera, home of Tech Dreams. This is our E-learning portal for Tech Enthusiasts. Apply below to register your interest in any of our courses.
          </p>
          <div className="flex space-x-4">
            <motion.button className="bg-primary-dark text-white px-4 py-2 md:px-6 md:py-2 rounded hover:bg-orange-600 transition duration-300"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            >
              GET STARTED
            </motion.button>
            {/* <motion.button className="bg-gray-300 text-black px-4 py-2 md:px-6 md:py-2 rounded hover:bg-gray-400 transition duration-300"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            >
              Contact Us
            </motion.button> */}
          </div>
        </div>

        {/* Image Div */}
        <div className="relative w-full md:w-[40%] bg-primary-dark rounded-full sm:p-10 md:p-20 lg:p-20 md:d hidden md:hidden lg:block">
          
          <div className="w-full flex justify-center bg-primary-main rounded-full md:p-40 lg:40">
            <img 
            src={require('../images/hero-boy.png')} 
            alt="Someone coding" 
            className="w-full md:w-fit object-contain absolute -top-[11%] -left-[17%]" 
          />
          </div>
        </div>
        
      </div>

      <div className='relative flex justify-center hidden md:hidden lg:block"'>
        <img src={require('../images/play-button.png')} alt=""  className='absolute -top-40 hidden md:block'/>
      </div>

    </div>

  )
}

export default Hero