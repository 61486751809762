import React from 'react'

function Benefits() {
  return (
    <div className='px-6 md:px-16 mt-6 md:mt-0 mb-10 md:mb-0'>
        <div className='bg-[#F5F5F5] text-primary-dark rounded-[10px] w-fit py-[10px] px-[25px]'>
            <p>Benefits</p>
        </div>
        <div className='md:flex gap-60 mt-5 md:mt-0'>
            <div className='w-full lg:w-[45%]'>
                <h1 className='font-bold text-[20px] md:text-[40px]'>Unveiling the Proficiencies you’ll master</h1>
                <div className='flex items-start mt-7 gap-3'>
                    <div className='bg-white shadow-lg shadow-slate-300 rounded-[100%] p-4'>
                        <img src={require('../images/gain.png')} alt="" height={50} width={50} />
                    </div>
                    <p>Gain In-Demand Skills: Master the latest tools, techniques, and technologies that are highly sought after in today’s job market.</p>
                </div>
                <div className='flex items-start mt-7 gap-3'>
                    <div className='bg-white shadow-lg shadow-slate-300 rounded-[100%] p-4'>
                        <img src={require('../images/boost.png')} alt="" height={50} width={50} />
                    </div>
                    <p>Boost Problem-Solving Abilities: Learn how to tackle real-world challenges efficiently with hands-on projects and case studies.</p>
                </div>
                <div className='flex items-start mt-7 gap-3'>
                    <div className='bg-white shadow-lg shadow-slate-300 rounded-[100%] p-4'>
                        <img src={require('../images/people.png')} alt="" height={50} width={50} />
                    </div>
                    <p>Enhance Collaboration: Improve teamwork and communication skills through industry-best practices, ensuring smoother project management and delivery.</p>
                </div>
            </div>
            <div className='w-[434px] mt-20 hidden md:block'>
                <img src={require('../images/pana.png')} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Benefits